<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit">
      <div slot="info" v-if="paramsId">
<!--        <span class="mr-20">创建时间：{{form.createTime}}</span>-->
<!--        <span class="mr-20">商品状态：{{form.statusName}}</span>-->
      </div>
      <div slot="content">
        <a-card title="商品信息">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
<!--            <a-row>-->
              <a-row>
                <a-col :span="10">
                  <a-form-model-item label="商品名称" prop="name">
                    <a-input v-model="form.name" :maxLength="50" allowClear :disabled="isDetail" placeholder="请输入商品名称"></a-input>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="10">
                  <a-form-model-item label="商品编码" prop="code">
                    <a-input v-model="form.code" :maxLength="50" allowClear :disabled="isDetail" placeholder="请输入商品编码"></a-input>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="10">
                  <a-form-model-item label="成本价格" prop="costPrice">
                    <a-input-number
                      placeholder="请输入成本价格"
                      v-model="form.costPrice"
                      size="large"
                      :maxLength="10"
                      :min="0"
                      :max="9999999999"
                      :disabled="isDetail"
                      allowClear
                    />
                    <span class="ml-20">
                        元
                      </span>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="10">
                  <a-form-model-item label="销售价格" prop="salePrice">
                    <a-input-number
                      placeholder="请输入销售价格"
                      v-model="form.salePrice"
                      size="large"
                      :maxLength="10"
                      :min="0"
                      :max="9999999999"
                      :disabled="isDetail"
                      allowClear
                    />
                    <span class="ml-20">
                        元
                      </span>
                  </a-form-model-item>
                </a-col>
              </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="库存变更" prop="inOrOutFlag">
                  <a-select v-model="form.inOrOutFlag" allowClear :disabled="isDetail">
                    <a-select-option :value="0" >
                      增加
                    </a-select-option>
                    <a-select-option :value="1">
                      减少
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="库存变更量" prop="balance">
                  <a-input-number
                    placeholder="请输入库存数量"
                    v-model="form.balance"
                    size="large"
                    :maxLength="10"
                    :min="0"
                    :max="9999999999"
                    :disabled="isDetail"
                    allowClear
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="规格" prop="spec">
                  <a-input v-model="form.spec" :maxLength="50" allowClear :disabled="isDetail" placeholder="请输入规格"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="单位" prop="unit">
                  <a-input v-model="form.unit" :maxLength="50" allowClear :disabled="isDetail" placeholder="请输入单位"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="商品类别" prop="categoryId">
                  <j-category-select
                    placeholder="请选择分类"
                    v-model="form.categoryId"
                    :disabled="isDetail"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="状态" prop="status">
                  <a-select v-model="form.status" allowClear :disabled="isDetail">
                    <a-select-option :value="0">
                      启用
                    </a-select-option>
                    <a-select-option :value="1">
                      禁用
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="享受会员折扣" prop="discountFlag">
                  <a-select v-model="form.discountFlag" allowClear :disabled="isDetail">
                    <a-select-option :value="0">
                      启用
                    </a-select-option>
                    <a-select-option :value="1">
                      禁用
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>
  </div>
</template>

<script>
import { goodsInfo, goodsSubmit } from '@/api/goods';
import formMixin from '@/mixin/formMixin';
import JCategorySelect from '@/components/JCategorySelect';

export default {
  name: 'goodsForm',
  mixins: [formMixin],
  components: {
    JCategorySelect
  },
  props: {},
  data() {
    return {
      goodsCategoryList: [],
      form: {
        name: '',
        code: '',
        costPrice: 0,
        salePrice: 0,
        unit: '',
        status: 0,
        discountFlag: 0,
        remark: '',
        inOrOutFlag: 0,
        categoryId: null,
        balance: 0
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入商品名称',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 50,
            message: '长度在1-50字之间',
            trigger: 'blur'
          }
        ],
        // costPrice: [
        //   {
        //     required: true,
        //     message: '请输入成本价格'
        //   }
        // ],
        salePrice: [
          {
            required: true,
            message: '请输入销售价格'
          }
        ],
        status: [
          {
            required: true,
            message: '请选择商品状态'
          }
        ],
        balance: [
          {
            required: true,
            message: '请输入库存数量'
          }
        ],
        inOrOutFlag: [
          {
            required: true,
            message: '请选择库存变更方式'
          }
        ]
      },
    };
  },
  computed: {
    paramsId() {
      return this.$route.params.id;
    }
  },
  watch: {},
  created() {
    if (this.paramsId) {
      this.getData(this.paramsId);
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取信息
    getData(productId) {
      const params = {
        productId
      };
      goodsInfo(params).then((data) => {
        this.form = data;
        this.form.inOrOutFlag = 0;
        this.form.balance = 0;
      });
    },
    handleSave() {
      const params = { ...this.form };
      goodsSubmit(params).then(() => {
        this.$message.success('操作成功！');
        this.handleBack();
      });
    },

    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.handleSave();
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
