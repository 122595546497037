import axios from '../libs/axios';

// 商品列表
const goodsList = (data) => {
  return axios.get('/api/trd/product/list', data);
};

// 商品信息
const goodsInfo = (data) => {
  return axios.get('/api/trd/product/info', data);
};

// 商品保存/修改
const goodsSubmit = (data) => {
  return axios.post('/api/trd/product/save', data);
};

// 启用/禁用商品
const goodsStatus = (data) => {
  return axios.get('/api/trd/product/status', data);
};

// 启用/禁用商品
const goodsDiscountFlag = (data) => {
  return axios.get('/api/trd/product/discountFlag', data);
};

// 所有可用商品列表 下单使用
const goodsAll = (data) => {
  return axios.get('/api/trd/product/all', data);
};

// 所有可用商品列表 下单使用
const goodsAllByCategoryId = (categoryId) => {
  return axios.get(`/api/trd/product/allByCategoryId/${categoryId}`);
};
// 商品保存/修改
const goodsLogExport = (data) => {
  return axios.post('/api/trd/product/export', data);
};

export {
  goodsList,
  goodsInfo,
  goodsSubmit,
  goodsStatus,
  goodsDiscountFlag,
  goodsAll,
  goodsLogExport,
  goodsAllByCategoryId
};
